import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WeekRangeSelectionStrategyService } from '@qaroni-core/services/app/date/week-range-selection-strategy.service';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { DayISO } from '@qaroni-core/types/date/days.enum';
import { RangeWeek } from '@qaroni-core/types/date/range-week';
import { WeekRangeConvert } from '@qaroni-core/utils/week-range-convert';
import {
  addDays,
  addWeeks,
  format,
  getISODay,
  getWeek,
  isValid,
  startOfYear,
  subYears,
} from 'date-fns';
import { WeekDatepickerForm } from './week-datepicker.form';

@Component({
  selector: 'qaroni-week-datepicker',
  templateUrl: './week-datepicker.component.html',
  styleUrl: './week-datepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: WeekRangeSelectionStrategyService,
    },
  ],
})
export class WeekDatepickerComponent extends WeekDatepickerForm {
  @Input() showWeekNumber = true;
  @Input() useQueryParam = true;
  @Input() weekNumberParamName = 'weekNumber';
  @Input() yearParamName = 'year';
  @Input() borderLess = true;

  @Output() date = new EventEmitter<Date>();
  @Output() currentWeek: EventEmitter<RangeWeek> =
    new EventEmitter<RangeWeek>();

  public weekNumber: number;
  public minDate = subYears(new Date(), 100);
  private currentDate: Date = new Date();

  public isLoading$ = this.progressBar.getProgressBar$();
  private queryParamMap$ = this.route.queryParamMap;

  constructor(
    private route: ActivatedRoute,
    private progressBar: ProgressBarService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.useQueryParam) {
      this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
    } else {
      this.getCurrentWeek();
    }
  }

  get currentYear(): string {
    return this.currentDate ? format(this.currentDate, 'yyyy') : '';
  }

  get ngConditionalsClasses() {
    return {
      invisible: !this.weekNumber,
      border: !this.borderLess,
    };
  }

  private getCurrentWeek(): void {
    this.currentDate = new Date();
    this.setCurrentWeekDates();
    this.weekNumber = this.getWeekNumber(this.startDate.value);

    this.currentWeek.emit({
      startDate: this.startDate.value,
      endDate: this.endDate.value,
    });
  }

  public onClosedDateRange() {
    this.currentDate = new Date(this.startDate.value);
    this.weekNumber = this.getWeekNumber(this.startDate.value);

    this.currentWeek.emit({
      startDate: this.startDate.value,
      endDate: this.endDate.value,
    });
    this.date.emit(this.currentDate);
  }

  public addWeek() {
    this.startDate.setValue(addWeeks(this.startDate.value, 1));
    this.endDate.setValue(addWeeks(this.endDate.value, 1));
    this.weekNumber = this.getWeekNumber(this.startDate.value);
    this.currentDate = new Date(this.startDate.value);

    this.currentWeek.emit({
      startDate: this.startDate.value,
      endDate: this.endDate.value,
    });

    this.date.emit(this.startDate.value);
  }

  public subtractWeek() {
    this.startDate.setValue(addWeeks(this.startDate.value, -1));
    this.endDate.setValue(addWeeks(this.endDate.value, -1));

    this.weekNumber = this.getWeekNumber(this.startDate.value);
    this.currentDate = new Date(this.startDate.value);

    this.currentWeek.emit({
      startDate: this.startDate.value,
      endDate: this.endDate.value,
    });

    this.date.emit(this.startDate.value);
  }

  private getWeekNumber(date: Date): number {
    return isValid(date)
      ? getWeek(date, {
          weekStartsOn: 1,
          firstWeekContainsDate: getISODay(startOfYear(new Date())) as DayISO,
        })
      : getWeek(new Date(), {
          weekStartsOn: 1,
          firstWeekContainsDate: getISODay(startOfYear(new Date())) as DayISO,
        });
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (
      queryParamMap.has(this.weekNumberParamName) &&
      queryParamMap.has(this.yearParamName)
    ) {
      const weekNumber: number = parseInt(
        queryParamMap.get(this.weekNumberParamName)
      );
      const year: number = parseInt(queryParamMap.get(this.yearParamName));

      if (Number.isInteger(weekNumber) && year) {
        this.weekNumber = weekNumber;

        const sunday = new Date(year, 0, 2 + weekNumber * 7);
        while (sunday.getDay() !== 0) {
          sunday.setDate(sunday.getDate() - 1);
        }

        this.currentDate = new Date(sunday);
        this.setCurrentWeekDates();

        this.currentWeek.emit({
          startDate: this.startDate.value,
          endDate: this.endDate.value,
        });
      }
    }
  };

  private setCurrentWeekDates(): void {
    const subtractDays = WeekRangeConvert.convert(this.currentDate)[0];
    const plusDays = WeekRangeConvert.convert(this.currentDate)[1];

    this.startDate.setValue(addDays(this.currentDate, subtractDays));
    this.endDate.setValue(addDays(this.currentDate, plusDays));
  }
}
