import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';
import { QueryParamSelectEnumFilterForm } from './query-param-select-enum-filter.form';

@Component({
  selector: 'qaroni-query-param-select-enum-filter',
  templateUrl: './query-param-select-enum-filter.component.html',
  styleUrl: './query-param-select-enum-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryParamSelectEnumFilterComponent<T>
  extends QueryParamSelectEnumFilterForm
  implements OnInit
{
  @Input() label: string = '';
  @Input() firstOption: string = $localize`Todos`;
  @Input({ required: true })
  enumInformation: QaroniEnumInformation<any> | null = null;
  @Input({ required: true }) enumArray: T[] = [];
  @Input({ required: true }) queryParamName = '';

  @Output() queryParamsChange = new EventEmitter<Params>();

  public valueKey: keyof T = 'key' as keyof T;
  public textKey: keyof T = 'value' as keyof T;

  private route = inject(ActivatedRoute);
  private queryParamMap$ = this.route.queryParamMap;

  ngOnInit(): void {
    if (!this.hasQueryParamName) {
      return;
    }

    this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
  }

  get hasLabel(): boolean {
    return this.label.trim().length > 0;
  }

  get hasQueryParamName(): boolean {
    return this.queryParamName.trim().length > 0;
  }

  get options(): T[] {
    return this.enumArray
      .filter(
        (value: any) =>
          value &&
          Object.prototype.hasOwnProperty.call(this.enumInformation, value)
      )
      .map((value: any) => ({
        key: value,
        value: this.enumInformation[value].name,
      })) as T[];
  }

  public getValue(item: T) {
    return this.valueKey ? item[this.valueKey] : item;
  }

  public getText(item: T) {
    return this.textKey ? String(item[this.textKey]) : String(item);
  }

  public onchange(event: MatSelectChange): void {
    const queryParamsEmit: Params = { ...this.route.snapshot.queryParams };
    if (event?.value) {
      queryParamsEmit[this.queryParamName] = this.paramValueText;
    } else {
      delete queryParamsEmit[this.queryParamName];
    }
    this.queryParamsChange.emit(queryParamsEmit);
  }

  public getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has(this.queryParamName)) {
      this.paramValueText = queryParamMap.get(this.queryParamName);
    } else {
      this.resetQueryParamName();
    }
  };
}
