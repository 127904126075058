<form [formGroup]="form">
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    floatLabel="always">
    <input
      matInput
      formControlName="search"
      autocomplete="off"
      type="text"
      i18n-placeholder
      [placeholder]="placeholder"
      (keydown.enter)="onKeydownEnter($event); $event.preventDefault()" />
    <mat-icon matPrefix fontIcon="search" />
    @if (search?.value) {
      <button
        @toggleFade
        mat-icon-button
        matSuffix
        type="button"
        i18n-aria-label
        aria-label="Limpiar"
        (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </mat-form-field>
</form>
