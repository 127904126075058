import { Injectable } from '@angular/core';
import {
  DateRange,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import { endOfWeek, startOfWeek } from 'date-fns';

@Injectable()
export class WeekRangeSelectionStrategyService<D>
  implements MatDateRangeSelectionStrategy<D>
{
  selectionFinished(date: D | null): DateRange<D> {
    return this._createFullWeekRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createFullWeekRange(activeDate);
  }

  private _createFullWeekRange(date: D | null): DateRange<D> {
    if (date) {
      const startOfWeekDate = startOfWeek(date as Date, { weekStartsOn: 1 });
      const endOfWeekDate = endOfWeek(date as Date, { weekStartsOn: 1 });

      return new DateRange<D>(
        startOfWeekDate as unknown as D,
        endOfWeekDate as unknown as D
      );
    }

    return new DateRange<D>(null, null);
  }
}
