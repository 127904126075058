import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckCommentDialogForm } from './check-comment-dialog.form';

@Component({
  selector: 'qaroni-check-comment-dialog',
  templateUrl: './check-comment-dialog.component.html',
  styleUrls: ['./check-comment-dialog.component.scss'],
})
export class CheckCommentDialogComponent extends CheckCommentDialogForm {
  constructor(
    @Inject(MAT_DIALOG_DATA) public checks: any,
    public dialogRef: MatDialogRef<CheckCommentDialogComponent>
  ) {
    super();
  }

  get validatedForm(): boolean {
    return this.dirtyValidForm && !this.submitting;
  }

  onSubmit() {
    if (this.validatedForm) {
      this.dialogRef.close(this.form.value);
    }
  }

  onCheckChange(event) {
    const check = this.checks.filter(check => check.checkId === event.value);
    this.index.setValue(check[0].index);
    this.comment.setValue(check[0].comment);
  }

  public onCancelClick(): void {
    this.dialogRef.close(null);
  }
}
