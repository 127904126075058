<td>
  <div class="icon-wrapper qaroni-text-background">
    <mat-icon>{{ plan?.icon }}</mat-icon>
  </div>
</td>
<td>
  <div class="me-2 text-sm">
    <div class="font-bold">{{ plan?.name }}</div>
    <ng-container *ngIf="hasPrice; else noRange">
      <ng-container *ngIf="isMonthly; else yearRange">
        <ng-container *ngTemplateOutlet="monthRange"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</td>
<td>
  <div class="flex flex-col items-start justify-center">
    <ng-container *ngIf="hasPrice; else noPrice">
      <ng-container *ngTemplateOutlet="monthPrice"></ng-container>
      <ng-container *ngTemplateOutlet="yearPrice"></ng-container>
    </ng-container>
  </div>
</td>

<ng-template #monthRange>
  <span class="font-light">
    {{ monthPlan?.minRange }} <ng-container i18n>a</ng-container>
    {{ monthPlan?.maxRange }} <ng-container i18n>empleados</ng-container>
  </span>
</ng-template>

<ng-template #yearRange>
  <span class="font-light">
    {{ yearPlan?.minRange }} <ng-container i18n>a</ng-container>
    {{ yearPlan?.maxRange }} <ng-container i18n>empleados</ng-container>
  </span>
</ng-template>

<ng-template #monthPrice>
  <div class="flex items-center">
    <mat-icon
      class="qaroni-mat-icon-20 mr-1 cursor-pointer text-primary hover:text-primary-40"
      matTooltip="Por empleado"
      fontIcon="info" />

    <span class="text-price text-lg font-bold">
      {{ monthPlan?.unitAmount | currency: monthPlan?.currency }}/
    </span>
    <ng-container i18n>mes</ng-container>
  </div>
</ng-template>

<ng-template #yearPrice>
  <div class="block">
    <div class="flex items-center">
      <mat-icon
        class="qaroni-mat-icon-20 mr-1 cursor-pointer text-primary hover:text-primary-40"
        matTooltip="Por empleado"
        fontIcon="info" />
      <span class="text-price text-lg font-bold">
        {{ yearPlan?.unitAmount | currency: yearPlan?.currency }}/
      </span>
      <ng-container i18n>año</ng-container>
    </div>
    <div class="text-discount">
      <del>
        {{ monthPlan?.unitAmount * 12 | currency: monthPlan?.currency }}
      </del>
      Obtén un 10% de descuento
    </div>
  </div>
</ng-template>

<ng-template #noRange>
  <span class="font-light" i18n>Mayor a 250 empleados</span>
</ng-template>

<ng-template #noPrice>
  <div class="text-lg font-bold">
    <a
      class="qaroni-link mb-1"
      (click)="onWeWillContactYou()"
      (keypress)="onWeWillContactYou()"
      i18n>
      ¡Te contactaremos!
    </a>
  </div>
</ng-template>
