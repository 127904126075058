<form [formGroup]="form" class="w-full" (ngSubmit)="onSubmit()">
  <button
    mat-button
    type="button"
    class="qaroni-close-icon"
    [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mat-mdc-dialog-title" i18n>Añadir o editar comentario</div>
  <mat-dialog-content class="mx-1 pb-3">
    <div class="flex w-full flex-col gap-3">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label i18n>Check</mat-label>
        <mat-select
          (selectionChange)="onCheckChange($event)"
          formControlName="checkId">
          <mat-option
            *ngFor="let check of checks; let i = index"
            [value]="check?.checkId">
            {{ check?.startDate }} - {{ check?.endDate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label i18n>Comentario</mat-label>
        <textarea
          matInput
          formControlName="comment"
          autocomplete="off"
          class="comment-text-area"
          rows="5"
          type="text"></textarea>
        <mat-error
          align="start"
          *ngIf="InputValidation.checkInputErrors(comment)">
          <qaroni-input-error-messages
            [fc]="comment"></qaroni-input-error-messages>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="flex w-full items-center justify-center gap-3">
      <button
        mat-flat-button
        color="light-blue"
        class="qaroni-rounded-button m-1"
        type="button"
        [mat-dialog-close]="false"
        i18n>
        Cancelar
      </button>

      <button
        mat-flat-button
        color="blue"
        class="qaroni-rounded-button m-1"
        type="submit"
        [disabled]="!validatedForm">
        <mat-icon class="me-1">save</mat-icon>
        <ng-container i18n>Guardar</ng-container>
      </button>
    </div>
  </mat-dialog-actions>
</form>
