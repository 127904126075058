<div
  class="flex min-h-14 min-w-56 items-center justify-center gap-1 rounded px-2 py-1"
  [ngClass]="ngConditionalsClasses">
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Mes anterior"
    (click)="subtractMonth()"
    [disabled]="isLoading$ | async">
    <mat-icon fontIcon="navigate_before" />
  </button>
  <div class="flex flex-col">
    <button
      mat-button
      i18n-matTooltip
      matTooltip="¿Cambiar fecha?"
      class="capitalize"
      (click)="dp.open()">
      {{ monthName }} - {{ year }}
    </button>
    <input
      matInput
      [matDatepicker]="dp"
      [formControl]="dateControl"
      [min]="minDate"
      [max]="maxDate"
      class="invisible h-0 w-0" />
    <mat-datepicker
      #dp
      startView="multi-year"
      (monthSelected)="setMonthAndYear($event, dp)"
      panelClass="month-picker" />
  </div>
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Mes siguiente"
    (click)="addMonth()"
    [disabled]="isLoading$ | async">
    <mat-icon fontIcon="navigate_next" />
  </button>
</div>
