import { AbstractControl, Validators } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';

export class ForgotPasswordDialogForm extends QaroniBaseFormsDirective {
  private skeleton = {
    email: ['', [Validators.required, Validators.email]],
    captcha: ['', Validators.required],
  };

  public form = this.fb.group(this.skeleton);

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get captcha(): AbstractControl {
    return this.form.get('captcha');
  }

  get dirtyValidForm(): boolean {
    return !this.form.dirty && this.form.valid;
  }
}
