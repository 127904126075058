import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import { debounceTime } from 'rxjs/operators';
import { EditorOptions } from 'tinymce';

@Component({
  selector: 'qaroni-tinymce-textarea',
  templateUrl: './tinymce-textarea.component.html',
  styleUrls: ['./tinymce-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TinymceTextareaComponent
  extends QaroniBaseSubsDirective
  implements OnInit
{
  @Input() fc: FormControl;

  public tinyInit: Partial<EditorOptions> = {
    base_url: '/tinymce',
    suffix: '.min',
    height: 225,
    branding: false,
    menubar: false,
    plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'wordcount',
    ],
    toolbar: `undo redo | formatselect | bold italic backcolor |
    alignleft aligncenter alignright alignjustify |
    bullist numlist outdent indent | link | insertmediaimage | removeformat | help`,
    license_key: 'gpl',
  };

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subs.add(
      this.fc?.valueChanges
        .pipe(debounceTime(500))
        .subscribe(this.getTinyValueChanges)
    );
  }

  private getTinyValueChanges = (valueChanges): void => {
    if (valueChanges) {
      this._cdr.markForCheck();
    }
  };
}
