import { apiQWorkardsEnvironment } from 'src/environments/environment';

// TODO: refactor without Q
export const ApiQWorkardsEnv = {
  baseUrl: apiQWorkardsEnvironment.hasOwnProperty('baseUrl')
    ? apiQWorkardsEnvironment.baseUrl
    : null,
  baseWorkstationUrl: apiQWorkardsEnvironment.hasOwnProperty(
    'baseWorkstationUrl'
  )
    ? apiQWorkardsEnvironment.baseWorkstationUrl
    : null,
};
