import { Directive, inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { InputValidation } from '@qaroni-core/utils/validations/input-validation';
import { QaroniBaseSubsDirective } from '../qaroni-base-subs/qaroni-base-subs.directive';

@Directive()
export class QaroniBaseFormsDirective extends QaroniBaseSubsDirective {
  protected fb = inject(UntypedFormBuilder);

  protected InputValidation = InputValidation;

  protected submitting = false;

  protected enableLoading(): void {
    this.submitting = true;
  }

  protected disableLoading(): void {
    this.submitting = false;
  }
}
