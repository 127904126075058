import { AbstractControl, Validators } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';
export class WeekDatepickerForm extends QaroniBaseFormsDirective {
  private skeleton = {
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  };

  public form = this.fb.group(this.skeleton);

  get startDate(): AbstractControl {
    return this.form.get('startDate');
  }

  get endDate(): AbstractControl {
    return this.form.get('endDate');
  }

  get dirtyValidForm(): boolean {
    return this.form.dirty && this.form.valid;
  }

  public populateCompanyUpdateForm(startDate, endDate): void {
    if (startDate && endDate) {
      this.form.patchValue({ startDate, endDate });
    }
  }
}
