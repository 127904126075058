import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import { addMonths, format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { shareReplay } from 'rxjs/operators';
import { YearMonthDatepickerForm } from './year-month-datepicker.form';

@Component({
  selector: 'qaroni-year-month-datepicker',
  templateUrl: './year-month-datepicker.component.html',
  styleUrls: ['./year-month-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearMonthDatepickerComponent
  extends YearMonthDatepickerForm
  implements OnInit, OnChanges
{
  @Input()
  get year(): number {
    return this._year;
  }
  set year(value: NumberInput) {
    this._year = coerceNumberProperty(value, 0);
  }
  @Input() borderLess = true;
  @Input() monthName: string;
  @Input() monthNumber: number;
  @Input() onlyCurrentYear = false;
  @Input() useQueryParam = true;
  @Output() date = new EventEmitter<string>();

  public isLoading$ = this.progressBar.getProgressBar$().pipe(shareReplay(1));
  private queryParamMap$ = this.route.queryParamMap;

  private today = new Date();
  private _year: number;

  constructor(
    private route: ActivatedRoute,
    private progressBar: ProgressBarService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.useQueryParam) {
      this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.useQueryParam) {
      this.year = this.year || this.today.getFullYear();
      this.monthName =
        this.monthName || format(this.today, 'MMMM', { locale: es });
      this.monthNumber = this.monthNumber || this.today.getMonth() + 1;

      this.currentDate = new Date(this.year, this.monthNumber - 1);
      this.dateControl.setValue(format(this.currentDate, 'yyyy-MM'));
    }

    if (this.onlyCurrentYear) {
      this.minDate = new Date(this.year, 0, 1);
    }
  }

  get showDatepicker(): boolean {
    return this.monthName && this.year ? true : false;
  }

  get ngConditionalsClasses() {
    return {
      invisible: !(this.monthName && this.year),
      border: !this.borderLess,
    };
  }

  public setMonthAndYear(
    normalizedMonthAndYear: Date,
    datepicker: MatDatepicker<Date>
  ) {
    this.dateControl.setValue(format(normalizedMonthAndYear, 'yyyy-MM'));
    this.date.emit(this.dateControl.value);
    datepicker.close();
  }

  public addMonth() {
    const date = addMonths(parseISO(this.dateControl.value), 1);
    this.dateControl.setValue(format(date, 'yyyy-MM'));
    this.date.emit(this.dateControl.value);
  }

  public subtractMonth() {
    const date = addMonths(parseISO(this.dateControl.value), -1);
    this.dateControl.setValue(format(date, 'yyyy-MM'));
    this.date.emit(this.dateControl.value);
  }
}
