import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import { ProgressBarService } from '@qaroni-core/services/app/progress-bar/progress-bar.service';
import {
  addYears,
  format,
  getYear,
  isValid,
  parseISO,
  subYears,
} from 'date-fns';

@Component({
  selector: 'qaroni-year-datepicker',
  templateUrl: './year-datepicker.component.html',
  styleUrls: ['./year-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearDatepickerComponent
  extends QaroniBaseSubsDirective
  implements OnInit, OnChanges
{
  @Input() title: string = '';
  @Input() year: string;
  @Input() useQueryParams = true;
  @Input() yearParamName = 'year';
  @Input() disableFutureYears = false;
  @Input() borderLess = true;

  @Output() dateYear: EventEmitter<Date> = new EventEmitter<Date>();

  public isLoading$ = this.progressBar.getProgressBar$();
  public yearControl = new UntypedFormControl();
  public currentDate: Date;
  public minDate = subYears(new Date(), 100);
  public maxDate = this.disableFutureYears
    ? new Date()
    : addYears(new Date(), 5);

  private queryParamMap$ = this.route.queryParamMap;

  constructor(
    private route: ActivatedRoute,
    private progressBar: ProgressBarService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.useQueryParams) {
      this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
    } else {
      this.setCurrentYear();
    }
  }

  ngOnChanges(): void {
    if (!this.useQueryParams) {
      const date: Date = parseISO(this.year);
      this.setValueYear(date);
    }
  }

  get isDisableFutureYears(): boolean {
    return (
      this.disableFutureYears && parseInt(this.year) >= getYear(new Date())
    );
  }

  get ngConditionalsClasses() {
    return {
      invisible: !this.year,
      border: !this.borderLess,
    };
  }

  public setYear(normalizedYear: Date, datepicker: MatDatepicker<Date>) {
    this.yearControl.setValue(normalizedYear);
    this.dateYear.emit(this.yearControl.value);
    datepicker.close();
  }

  private setCurrentYear() {
    const today = new Date();
    this.yearControl.setValue(format(today, 'yyyy-MM-dd'));
  }

  private setValueYear(date: Date) {
    const currentDate = isValid(date) ? date : new Date();
    this.yearControl.setValue(format(currentDate, 'yyyy-MM-dd'));
  }

  public addYear() {
    this.yearControl.setValue(addYears(parseISO(this.yearControl.value), 1));
    this.dateYear.emit(this.yearControl.value);
  }

  public subtractYear() {
    this.yearControl.setValue(addYears(parseISO(this.yearControl.value), -1));
    this.dateYear.emit(this.yearControl.value);
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has(this.yearParamName)) {
      const date: Date = parseISO(queryParamMap.get(this.yearParamName));
      this.currentDate = isValid(date) ? date : new Date();
      this.yearControl.setValue(format(this.currentDate, 'yyyy-MM-dd'));
    } else {
      this.setCurrentYear();
    }
  };
}
