<div
  class="flex min-h-14 min-w-56 items-center justify-center gap-1 rounded px-2 py-1"
  [ngClass]="ngConditionalsClasses">
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Semana anterior"
    (click)="subtractWeek()"
    [disabled]="isLoading$ | async">
    <mat-icon fontIcon="navigate_before" />
  </button>
  <div class="flex flex-col">
    <button
      mat-button
      i18n-matTooltip
      class="h-full"
      matTooltip="¿Cambiar semana?"
      (click)="picker.open()">
      <div class="inline-flex flex-col">
        @if (showWeekNumber) {
          <span class="leading-none">
            <ng-container i18n>Semana</ng-container> #{{ weekNumber }}
          </span>
        }
        <span class="mt-0.5 leading-none">
          <ng-container i18n>Del</ng-container>
          {{ startDate.value | date: 'dd/MM' }}
          <ng-container i18n>al</ng-container>
          {{ endDate.value | date: 'dd/MM' }}
          <ng-container i18n>de</ng-container>
          {{ currentYear }}
        </span>
      </div>
    </button>
    <mat-date-range-input
      [formGroup]="form"
      [rangePicker]="picker"
      class="invisible h-0 w-0"
      [min]="minDate">
      <input matStartDate formControlName="startDate" />
      <input matEndDate formControlName="endDate" />
    </mat-date-range-input>
    <mat-date-range-picker #picker (closed)="onClosedDateRange()" />
  </div>
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Semana siguiente"
    (click)="addWeek()"
    [disabled]="isLoading$ | async">
    <mat-icon fontIcon="navigate_next" />
  </button>
</div>
