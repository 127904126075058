import { AbstractControl, Validators } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';

export class CheckCommentDialogForm extends QaroniBaseFormsDirective {
  private skeleton = {
    index: [],
    checkId: ['', Validators.required],
    comment: [''],
  };

  public form = this.fb.group(this.skeleton);

  get index(): AbstractControl {
    return this.form.get('index');
  }

  get checkId(): AbstractControl {
    return this.form.get('checkId');
  }

  get comment(): AbstractControl {
    return this.form.get('comment');
  }

  get dirtyValidForm(): boolean {
    return this.form.dirty && this.form.valid;
  }
}
