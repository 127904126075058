import { inject } from '@angular/core';
import { OAuth } from '@qaroni-app/auth/types/o-auth';
import { StorageUtilsService } from '@qaroni-core/services/app/storage-utils/storage-utils.service';
import { Subject } from 'rxjs';

export abstract class OAuthStorageBaseService<T extends OAuth> {
  protected abstract lsKey: string;
  private oAuth: T;
  protected readonly oAuthSubject = new Subject<T>();

  private storageUtilsService = inject(StorageUtilsService);

  public get(): T {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      return JSON.parse(localStorage.getItem(this.lsKey));
    } else {
      return this.oAuth;
    }
  }

  public get$() {
    return this.oAuthSubject.asObservable();
  }

  public set(oAuth: T): void {
    if (this.storageUtilsService.validateLocalStorage) {
      localStorage.setItem(this.lsKey, JSON.stringify(oAuth));
    } else {
      this.oAuth = oAuth;
    }
    this.oAuthSubject.next(oAuth);
  }

  public reset(): void {
    if (this.storageUtilsService.validateInLocalStorage(this.lsKey)) {
      localStorage.removeItem(this.lsKey);
    } else {
      this.oAuth = null;
    }
    this.oAuthSubject.next(null);
  }

  get hasOAuth(): boolean {
    const oAuth = this.get();
    return !!(oAuth && oAuth.token && oAuth.userId);
  }
}
