import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectStatusEnum } from '@qaroni-app/projects/types/project-status.enum';

@Component({
  selector: 'qaroni-project-preview-status',
  templateUrl: './project-preview-status.component.html',
  styleUrls: ['./project-preview-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPreviewStatusComponent {
  @Input() status: ProjectStatusEnum = ProjectStatusEnum.PENDING;

  get bgColorClass(): string {
    switch (this.status) {
      case ProjectStatusEnum.PENDING:
        return 'qaroni-bg-tertiary';
      case ProjectStatusEnum.PROGRESS:
        return 'qaroni-bg-success';
      case ProjectStatusEnum.INACTIVE:
        return 'qaroni-bg-accent';
      case ProjectStatusEnum.CANCELLED:
        return 'qaroni-bg-error50';
      case ProjectStatusEnum.FINISHED:
        return 'qaroni-bg-primary60';
      default:
        return 'qaroni-bg-tertiary';
    }
  }
}
