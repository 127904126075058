import { Injectable } from '@angular/core';
import { OAuthStorageBaseService } from '@qaroni-core/types/o-auth/o-auth-storage-base-services';
import { OAuth } from '../types/o-auth';

@Injectable({
  providedIn: 'root',
})
export class OAuthStorageService extends OAuthStorageBaseService<OAuth> {
  protected lsKey = 'oAuth';
}
