import {
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
} from 'date-fns';

export class WeekRangeConvert {
  public static convert(date: Date): [number, number] {
    if (isMonday(date)) return [0, 6];

    if (isTuesday(date)) return [-1, 5];

    if (isWednesday(date)) return [-2, 4];

    if (isThursday(date)) return [-3, 3];

    if (isFriday(date)) return [-4, 2];

    if (isSaturday(date)) return [-5, 1];

    if (isSunday(date)) return [-6, 0];

    return [null, null];
  }
}
