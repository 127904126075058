import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';
import { QueryParamSearchFilterForm } from './query-param-search-filter.form';

@Component({
  selector: 'qaroni-query-param-search-filter',
  templateUrl: './query-param-search-filter.component.html',
  styleUrl: './query-param-search-filter.component.scss',
  animations: [qaroniAnimations.toggleFade()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryParamSearchFilterComponent
  extends QueryParamSearchFilterForm
  implements OnInit
{
  @Input() public placeholder = $localize`Buscar`;
  @Input() public queryParamName = 'search';

  @Output() queryParamsChange = new EventEmitter<Params>();

  private route = inject(ActivatedRoute);
  private queryParamMap$ = this.route.queryParamMap;

  ngOnInit() {
    this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
  }

  public onKeydownEnter(event): void {
    if (this.canSearch) {
      this.onSearch();
    } else if (this.searchText === '') {
      this.onClear();
    }
  }

  public onClear(): void {
    this.resetSearch();
    const queryParamsEmit: Params = { ...this.route.snapshot.queryParams };
    delete queryParamsEmit.search;
    this.queryParamsChange.emit(queryParamsEmit);
  }

  private onSearch(): void {
    const queryParamsEmit: Params = { ...this.route.snapshot.queryParams };
    queryParamsEmit.search = this.searchText;
    this.queryParamsChange.emit(queryParamsEmit);
  }

  public getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has(this.queryParamName)) {
      this.searchText = queryParamMap.get(this.queryParamName);
    } else {
      this.resetSearch();
    }
  };
}
