import { AbstractControl } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';

export class QueryParamSelectEnumFilterForm extends QaroniBaseFormsDirective {
  private skeleton = {
    paramValue: [''],
  };

  public form = this.fb.group(this.skeleton);

  get paramValue(): AbstractControl {
    return this.form.get('paramValue');
  }

  get paramValueText() {
    return this.paramValue.value.trim();
  }

  set paramValueText(valueText: string) {
    valueText = valueText.trim();
    if (valueText && typeof valueText === 'string') {
      this.paramValue.setValue(valueText);
    } else {
      this.paramValue.setValue('');
    }
  }

  public resetQueryParamName(): void {
    this.paramValue.setValue('');
  }
}
