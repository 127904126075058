<mat-button-toggle-group
  [formControl]="control"
  class="min-h-14 items-center px-1"
  hideSingleSelectionIndicator
  color="blue">
  @for (view of viewsArray; track view) {
    <mat-button-toggle
      [matTooltip]="getTooltipText(view)"
      [value]="view"
      class="overflow-hidden rounded border-none">
      <mat-icon class="material-icons-outlined" [fontIcon]="view" />
    </mat-button-toggle>
  }
</mat-button-toggle-group>
