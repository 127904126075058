import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { QueryParamSelectObjectFilterForm } from './query-param-select-object-filter.form';

@Component({
  selector: 'qaroni-query-param-select-object-filter',
  templateUrl: './query-param-select-object-filter.component.html',
  styleUrl: './query-param-select-object-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryParamSelectObjectFilterComponent<T>
  extends QueryParamSelectObjectFilterForm
  implements OnInit
{
  @Input() label: string = '';
  @Input() firstOption: string = $localize`Todos`;
  @Input() firstOptionValue: string = '';
  @Input() showFirstOption = true;
  @Input({ required: true }) options: T[] = [];
  @Input({ required: true }) valueKey: keyof T | null = null;
  @Input({ required: true }) textKey: keyof T | null = null;
  @Input({ required: true }) queryParamName = '';

  @Output() queryParamsChange = new EventEmitter<Params>();

  private route = inject(ActivatedRoute);
  private queryParamMap$ = this.route.queryParamMap;

  ngOnInit(): void {
    if (!this.hasQueryParamName) {
      return;
    }

    this.subs.add(this.queryParamMap$.subscribe(this.getQueryParamMap));
  }

  get hasQueryParamName(): boolean {
    return this.queryParamName.trim().length > 0;
  }

  get hasConfiguration(): boolean {
    return this.hasQueryParamName && !!this.textKey && !!this.valueKey;
  }

  get hasLabel(): boolean {
    return this.label.trim().length > 0;
  }

  public getValue(item: T) {
    return this.valueKey ? item[this.valueKey] : item;
  }

  public getText(item: T) {
    return this.textKey ? String(item[this.textKey]) : String(item);
  }

  public onchange(event: MatSelectChange): void {
    const queryParamsEmit: Params = { ...this.route.snapshot.queryParams };

    if (event.value !== '') {
      queryParamsEmit[this.queryParamName] = this.paramValueText;
    } else {
      delete queryParamsEmit[this.queryParamName];
    }
    this.queryParamsChange.emit(queryParamsEmit);
  }

  public getQueryParamMap = (queryParamMap: ParamMap): void => {
    if (queryParamMap.has(this.queryParamName)) {
      this.paramValueText = queryParamMap.get(this.queryParamName);
    } else {
      this.resetQueryParamName();
    }
  };
}
