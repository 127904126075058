import { AbstractControl } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';

export class QueryParamSelectObjectFilterForm extends QaroniBaseFormsDirective {
  private skeleton = {
    paramValue: [''],
  };

  public form = this.fb.group(this.skeleton);

  get paramValue(): AbstractControl {
    return this.form.get('paramValue');
  }

  get paramValueText() {
    return this.paramValue.value;
  }

  set paramValueText(value: any) {
    const convertValue = isNaN(value) ? value.trim() : parseInt(value);
    this.paramValue.setValue(convertValue);
    this.paramValue.markAsDirty();
  }

  public resetQueryParamName(): void {
    this.paramValue.setValue('');
  }
}
