import { AbstractControl } from '@angular/forms';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';

export class QueryParamSearchFilterForm extends QaroniBaseFormsDirective {
  private skeleton = {
    search: [''],
  };

  public form = this.fb.group(this.skeleton);

  get search(): AbstractControl {
    return this.form.get('search');
  }

  get dirtyValidForm(): boolean {
    return this.form.dirty && this.form.valid;
  }

  get canSearch(): boolean {
    return this.dirtyValidForm &&
      typeof this.search.value === 'string' &&
      this.search.value !== '' &&
      this.search.value !== null
      ? true
      : false;
  }

  get searchText(): string {
    return this.search.value.trim();
  }

  set searchText(value: string) {
    value = value.trim();
    if (value && typeof value === 'string') {
      this.search.setValue(value);
    } else {
      this.search.setValue('');
    }
  }

  public resetSearch(): void {
    this.searchText = '';
  }
}
