import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import {
  QaroniViewsArray,
  QaroniViewsEnum,
  QaroniViewsEnumType,
} from '@qaroni-shared/types/qaroni-views.enum';

@Component({
  selector: 'qaroni-toggle-view',
  templateUrl: './toggle-view.component.html',
  styleUrl: './toggle-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleViewComponent
  extends QaroniBaseSubsDirective
  implements OnInit
{
  @Input() set selectedView(value: QaroniViewsEnumType) {
    this._selectedView = value;
    this.control.setValue(value);
  }
  get selectedView(): QaroniViewsEnumType {
    return this._selectedView;
  }
  @Input() listViewTooltip = $localize`Ver como lista`;
  @Input() gridViewTooltip = $localize`Ver como cuadrícula`;

  @Output() viewChange = new EventEmitter<QaroniViewsEnumType>();

  public control = new FormControl(this.selectedView);
  public views = QaroniViewsEnum;
  public viewsArray = QaroniViewsArray;

  private _selectedView: QaroniViewsEnumType = QaroniViewsEnum.LIST;

  ngOnInit(): void {
    this.subs.add(this.control.valueChanges.subscribe(this.getViewChange));
  }

  public getTooltipText(view: QaroniViewsEnumType): string {
    return view === QaroniViewsEnum.LIST
      ? this.listViewTooltip
      : this.gridViewTooltip;
  }

  private getViewChange = (view: QaroniViewsEnumType) => {
    this.viewChange.emit(view);
  };
}
