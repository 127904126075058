import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { qaroniAnimations } from '@qaroni-shared/animations/qaroni-animations';

@Component({
  selector: 'qaroni-title-status-chip',
  templateUrl: './title-status-chip.component.html',
  styleUrl: './title-status-chip.component.scss',
  animations: [qaroniAnimations.enterFadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleStatusChipComponent {
  @Input() status: string = '';
  @Input() matTooltipText = '';
  @Input() bgClassColorName = 'qaroni-bg-primary80';
}
