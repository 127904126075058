import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { QaroniBaseSubsDirective } from '@qaroni-core/directives/qaroni-base-subs/qaroni-base-subs.directive';
import { AdvancedFiltersService } from '@qaroni-core/services/app/advanced-filters/advanced-filters.service';

@Component({
  selector: 'qaroni-advanced-filters-button',
  templateUrl: './advanced-filters-button.component.html',
  styleUrl: './advanced-filters-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedFiltersButtonComponent
  extends QaroniBaseSubsDirective
  implements OnInit, OnDestroy
{
  public active: boolean = false;
  private advancedFiltersService = inject(AdvancedFiltersService);
  private advancedFilterStatus$ =
    this.advancedFiltersService.getAdvancedFiltersStatus$();

  ngOnInit(): void {
    this.subs.add(this.advancedFilterStatus$.subscribe(this.getStatus));
  }

  ngOnDestroy(): void {
    this.advancedFiltersService.changeStatus(false);
  }

  get icon(): string {
    return this.active ? 'filter_list_off' : 'filter_list';
  }

  public onClick(): void {
    this.active = !this.active;
    this.advancedFiltersService.changeStatus(this.active);
  }

  private getStatus = (status: boolean): void => {
    this.active = status;
  };

  get tooltipText(): string {
    return this.active
      ? $localize`Ocultar filtros`
      : $localize`Mostrar filtros`;
  }
}
