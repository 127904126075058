<form [formGroup]="form">
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    floatLabel="always">
    <mat-label i18n>Fechas</mat-label>
    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-input
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate">
      <input
        matStartDate
        formControlName="dateFrom"
        placeholder="Inicio"
        i18n-placeholder />
      <input
        matEndDate
        formControlName="dateTo"
        placeholder="Fin"
        i18n-placeholder />
    </mat-date-range-input>
    @if (hasDates) {
      <button
        mat-icon-button
        matSuffix
        type="button"
        i18n-aria-label
        aria-label="Limpiar"
        (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    }
    <mat-date-range-picker
      #picker
      (closed)="closeDatepicker()"
      disabled="false"></mat-date-range-picker>
  </mat-form-field>
</form>
