<div
  class="flex items-center justify-between overflow-hidden rounded-md border">
  <button
    mat-flat-button
    class="min-h-[54px] w-full rounded-none"
    [matTooltip]="tooltipText"
    (click)="onClick()">
    <mat-icon class="mr-1 shrink-0" [fontIcon]="icon" />
    <ng-container i18n>Filtros avanzados</ng-container>
  </button>
</div>
