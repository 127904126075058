<form [formGroup]="form">
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    floatLabel="always">
    @if (hasLabel) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-select
      formControlName="paramValue"
      hideSingleSelectionIndicator
      (selectionChange)="onchange($event)">
      <mat-option value="">{{ firstOption }}</mat-option>
      @for (option of options; track getValue(option)) {
        <mat-option [value]="getValue(option)">
          {{ getText(option) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</form>
