import { AbstractControl, Validators } from '@angular/forms';
import {
  InvitationRoleSelectorArray,
  UserRoleEnum,
} from '@qaroni-app/workers/types/user-role.enum';
import { QaroniBaseFormsDirective } from '@qaroni-core/directives/qaroni-base-forms/qaroni-base-forms.directive';

export class SendInvitationDialogForm extends QaroniBaseFormsDirective {
  public roles = InvitationRoleSelectorArray;

  private skeleton = {
    email: ['', [Validators.required, Validators.email]],
    role: [UserRoleEnum.ADMIN, Validators.required],
  };

  public form = this.fb.group(this.skeleton);

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get role(): AbstractControl {
    return this.form.get('role');
  }

  get dirtyValidForm(): boolean {
    return this.form.dirty && this.form.valid;
  }
}
