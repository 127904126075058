<div
  class="flex min-h-14 items-center justify-center gap-1 rounded"
  [ngClass]="ngConditionalsClasses">
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Año anterior"
    (click)="subtractYear()"
    [disabled]="isLoading$ | async">
    <mat-icon fontIcon="navigate_before" />
  </button>
  <div class="flex flex-col">
    <button
      mat-button
      i18n-matTooltip
      matTooltip="¿Cambiar año?"
      (click)="dp.open()">
      @if (title !== '') {
        {{ title }}
      }
      {{ year }}
      <br />
    </button>
    <input
      matInput
      [matDatepicker]="dp"
      [formControl]="yearControl"
      [min]="minDate"
      [max]="maxDate"
      class="invisible"
      style="width: 0; height: 0" />
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="setYear($event, dp)"
      panelClass="year-picker" />
  </div>
  <button
    mat-icon-button
    i18n-matTooltip
    matTooltip="Año siguiente"
    (click)="addYear()"
    [disabled]="(isLoading$ | async) || isDisableFutureYears">
    <mat-icon fontIcon="navigate_next" />
  </button>
</div>
